<template>
  <section class="about">
    <v-flex xs12 class="ctk-top-banner">
      <v-img
        src="imgs/banner_quality.png"
        class="ctk-top-banner"
        >
      </v-img>
    </v-flex>
    <v-container  grid-list-md text-center class="ctk-div-m">
      <v-layout wrap>
        <v-flex xs12 sm12 md12 lg12 pa-2 pa-md-4>
          <h5 class="ctk-q-title">上海彩覆公司：专业包覆室外高耐候膜</h5>
          <h5 class="ctk-q-title">德国RENOLIT雷诺丽特的高耐候膜的优势</h5>
        </v-flex>
        <v-flex xs12 sm12 md12 lg12 mt-4 mb-2 pa-2 pa-md-4>
          <h5 class="ctk-q-title">业务辐射60多个国家及地区，拥有员工5000多名，生产室内、户外、家具膜、橱柜膜等等。</h5>
        </v-flex>
        <v-flex xs6 sm6 md3 lg3 pa-2 pa-md-4>
          <v-img
            src="imgs/q1.png"
            contain
          ></v-img>
          <h4 class="ctk-q-pic-title">沃尔姆斯工厂</h4>
        </v-flex>
        <v-flex xs6 sm6 md3 lg3 pa-2 pa-md-4>
          <v-img
            src="imgs/q2.png"
            contain
          ></v-img>
          <h4 class="ctk-q-pic-title">慕尼黑工厂</h4>
        </v-flex>
        <v-flex xs6 sm6 md3 lg3 pa-2 pa-md-4>
          <v-img
            src="imgs/q3.png"
            contain
          ></v-img>
          <h4 class="ctk-q-pic-title">米兰工厂</h4>
        </v-flex>
        <v-flex xs6 sm6 md3 lg3 pa-2 pa-md-4>
          <v-img
            src="imgs/q4.png"
            contain
          ></v-img>
          <h4 class="ctk-q-pic-title">广州工厂</h4>
        </v-flex>
        <v-flex xs6 sm6 md3 lg3 pa-2 pa-md-4>
          <v-img
            src="imgs/q5.png"
            contain
          ></v-img>
          <h4 class="ctk-q-pic-title">克拉姆灵顿工厂</h4>
        </v-flex>
        <v-flex xs12 sm12 md12 lg12 mt-4 mb-2 pa-2 pa-md-4>
          <h5 class="ctk-q-title" style="margin: 30px 0;">RENOLIT SE 全球发展和大中华区销售服务</h5>
          <v-img
            src="imgs/q_g.png"
            contain
          ></v-img>
        </v-flex>
        <v-flex xs12 sm12 md12 lg12 mt-4 mb-2 pa-2 pa-md-4 text-left class="ctk-q-ab">
          <h5 class="ctk-q-title" style="margin-bottom: 30px;">上海德硅公司：德国RENOLIT公司大中华区总经销（18年）</h5>
          <p>上海彩覆公司（上海德硅公司控股），专业在各种材料表面覆膜公司</p>
          <p>上海彩覆公司成立于2004年，年覆膜10万平方米。</p>
          <p>PVC型材表面覆室外膜，加工成塑料门窗，护墙板等室外装饰材。</p>
          <p>在铝型材表面覆膜，加工成铝合金门窗，栏杆，阳光房等等。</p>
          <p>在铝板、钢板表面覆膜，生产室外门，车库门等。</p>
          <p>室内覆膜的墙板，装饰线条，门窗室内覆膜。</p>
        </v-flex>
        <v-flex xs12 sm12 md6 lg6 mt-4 mb-2 pa-2 pa-md-4 text-left class="ctk-q-xl">
          <h5 class="ctk-q-title">RENOLIT EXOFOL MX</h5>
          <h5 class="ctk-q-title" style="margin-bottom: 30px;">雷诺丽特户外高效装饰膜  MX系列 <span style="color:#ccc;font-size:12px;">(检测结果见本页下方图片)</span></h5>
          <p><b>MX系列：</b>双层膜结构，紫外线吸收率超过95%，具有太阳热能屏蔽技术（SST），有效屏蔽热能对型材的影响，用来抵御外界气候的不稳定性。</p>
          <p><b>MX上膜：</b>50μm PMMA层，加工性能好，能经受住高温，紫外线，强阳光照射，耐溶剂和常用化学品。</p>
          <p><b>MX底膜：</b>添加了能够反射红外线的全着色PVC层，采用聚合物（大分子）软化剂，使膜柔软易加工，不易游离释放，不引影响剥离强度，同时非常环保。MX系列在欧洲被证明使用超过30年仍旧靓丽如新。</p>
        </v-flex>
        <v-flex xs12 sm12 md6 lg6 mt-4 mb-2 pa-2 pa-md-4>
          <v-img
            src="imgs/q_r.png"
            contain
          ></v-img>
        </v-flex>
        <v-flex xs12 sm12 md6 lg6 mt-4 mb-2 pa-2 pa-md-4>
          <v-img
            src="imgs/q_l.png"
            contain
          ></v-img>
        </v-flex>
        <v-flex xs12 sm12 md6 lg6 mt-4 mb-2 pa-2 pa-md-4 text-left class="ctk-q-xl">
          <h5 class="ctk-q-title">RENOLIT EXOFOL FX</h5>
          <h5 class="ctk-q-title" style="margin-bottom: 30px;">雷诺丽特户外高效装饰膜  FX系列 <span style="color:#ccc;font-size:12px;">(检测结果见本页下方图片)</span></h5>
          <p><b>FX系列：</b>三层膜结构，具有很强的抗紫外线性能，具有太阳热能屏蔽技术（SST），有效屏蔽热能对型材的影响，自洁性能特别适用于高层建筑的幕墙外观，世界各地的各种气候条件。</p>
          <p><b>FX上膜：</b>20μmPVDF+30μmPMMA，PVDF是很强韧性，很强耐候性的氟塑料，具有耐老化，耐臭氧，耐辐射，电绝缘性优异，耐腐蚀性强等优点，结构非常致密，张力小，使膜表面拥有“荷叶”的特性，增添了产品的自洁功能。</p>
          <p><b>FX底膜：</b>130μmPMMA，PMMA中可以加进更多具有反射红外线的色素，使产品耐候性更佳。</p>
          <p><b>背涂层：</b>保障可加工性</p>
        </v-flex>
        <v-flex xs12 sm12 md12 lg12 mt-4 mb-2 pa-2 pa-md-4 text-left class="ctk-q-xl">
          <h5 class="ctk-q-title" style="margin-bottom: 30px;">RENOLIT EXTERIOR  SST 太阳热能屏蔽技术 <span style="color:#ccc;font-size:12px;">(检测结果见本页下方图片)</span></h5>
          <p><b>原理：</b>上膜采用具有红外线通透性的PMMA（聚丙烯酸盐）或PVDF（聚偏氟乙烯）。<br/>底膜中加入反射红外线的色素，减少单色和木纹色膜对热量的吸收，降低基材内外面的温度差。</p>
          <p><b>性能：</b>有效阻止基材在强烈光照下升温变形，提高基材结构稳定性，防止老化，延长覆膜建筑部品的生命周期；并通过加强门窗的气密性，有效提高能源的使用效率。</p>
          <p style="color:#666; font-size: 16px;margin-bottom: 20px;">＊    雷诺丽特户外高效装饰膜专用的PMMA和PVDF，纯度高，使红外线照射和反射过程畅通无阻。（如果纯度不高，容易吸热升温。）</p>
        </v-flex>
        <v-flex xs12 sm12 md4 lg4 mt-4 mb-2 pa-2 pa-md-4>
          <v-img
            max-height="318"
            src="imgs/q_js_l.png"
            contain
            position="left"
          ></v-img>
        </v-flex>
        <v-flex xs12 sm12 md8 lg8 mt-4 mb-2 pa-2 pa-md-4>
          <v-img
            max-height="318"
            src="imgs/q_js_r.png"
            contain
            position="left"
          ></v-img>
        </v-flex>
        <v-flex xs12 sm12 md6 lg6 mt-4 mb-2 pa-2 pa-md-4 text-left class="ctk-q-xl">
          <h5 class="ctk-q-title" style="margin-bottom: 30px;">RENOLIT EXTERIOR  气候测试 <span style="color:#ccc;font-size:12px;">(检测结果见本页下方图片)</span></h5>
          <p>•   雷诺丽特所有户外膜产品上市前都要做相应的气候适应性检测，以确保所有户外产品都能有效的抵御太阳强光，以及潮湿气候的影响</p>
          <p>•   测试将在人造的恶劣环境下进行，太阳光测试是利用X光紫外线照射机器进行</p>
          <p style="margin-left: 30px;">•   耐候性也就是指膜针对日照射（特别是紫外线）以及湿度影响的颜色稳定性</p>
          <p style="margin-left: 30px;">•   耐候性测试是通过采用测试老化和风化的氙测试仪器来进行人工测试。根据RAL GZ 716/1 以及ISO 105-A02和模板，照射过和没有照射过的试样进行测试：观察颜色的变化</p>
          <p style="margin-left: 30px;">•   经过辐射后的试样，膜的颜色变化，符合真实性的-灰色等级3</p>
          <p>•   色差标准时依据RAL GZ 716/1，此标准时依据ISO 105-A02标准修订</p>
        </v-flex>
        <v-flex xs12 sm12 md6 lg6 mt-4 mb-2 pa-2 pa-md-4>
          <v-img
            src="imgs/q_paper.png"
            contain
          ></v-img>
        </v-flex>
        <v-flex xs12 sm12 md12 lg12 mt-4 mb-2 pa-2 pa-md-4 text-left class="ctk-q-xl">
          <h5 class="ctk-q-title" style="margin-bottom: 30px;">RENOLIT EXTERIOR 检测证书</h5>
            <p>气候适应性认证由 (SKZ) —— 德国南方质检中心，这一独立研究机构进行，所有雷诺丽特户外膜都远远超过RAL GZ 716的专业检测标准</p>
            <p>国内通过国家化学建筑材料测试中心12000小时耐候检测</p>
            <p>10,000 小时  约12.5 年 中欧阳光照射时间 （约10年 在中国阳光照射时间）</p>
            <p>针对中国地区的使用，PX提供5年、FX提供10年的质保证书</p>
        </v-flex>
        <v-flex xs6 sm6 md3 lg3 pa-2 pa-md-4>
          <v-img
            src="imgs/q_zs1.png"
            contain
          ></v-img>
        </v-flex>
        <v-flex xs6 sm6 md3 lg3 pa-2 pa-md-4>
          <v-img
            src="imgs/q_zs2.png"
            contain
          ></v-img>
        </v-flex>
        <v-flex xs6 sm6 md3 lg3 pa-2 pa-md-4>
          <v-img
            src="imgs/q_zs3.png"
            contain
          ></v-img>
        </v-flex>
        <v-flex xs6 sm6 md3 lg3 pa-2 pa-md-4>
          <v-img
            src="imgs/q_zs4.png"
            contain
          ></v-img>
        </v-flex>
        <v-flex xs12 sm12 md12 lg12 mt-4 pa-2 pa-md-4 text-left class="ctk-q-xl">
          <h5 class="ctk-q-title" style="margin-bottom: 30px;">RENOLIT EXTERIOR  气候测试</h5>
          <p>户外的测试则是由独立机构进行，测试地点包括在亚利桑那，佛罗里达，法国南部，及澳大利亚</p>
        </v-flex>
        <v-flex xs6 sm6 md3 lg3 pa-2 pa-md-4>
          <v-img
            src="imgs/q_b1.png"
            contain
          ></v-img>
        </v-flex>
        <v-flex xs6 sm6 md3 lg3 pa-2 pa-md-4>
          <v-img
            src="imgs/q_b2.png"
            contain
          ></v-img>
        </v-flex>
        <v-flex xs12 sm12 md12 lg12 mt-2 mb-2 pa-2 pa-md-4 text-left class="ctk-q-xl">
          <p>在不同的气候条件下进行测试，每12个月样品都要进行重复测试</p>
        </v-flex>
      </v-layout>
    </v-container>
  </section>
</template>
<script>
export default {
  data: () => ({
    pageId: 1,
    innerWidth: window.innerWidth
  }),
  created () {
    document.querySelector('#menu_quality').classList.add('v-btn--active')
  },
  watch: {
    '$store.getters.companyInfo': {
      handler: function (val, oldVal) {
        this.info.banners = val.banner[this.pageId]
      },
      deep: true
    }
  },
  computed: {
    info () {
      let banners = {}
      banners[this.pageId] = []
      if (this.$store.getters.companyInfo.banner) {
        banners = this.$store.getters.companyInfo.banner[this.pageId]
      }
      return {
        banners: banners
      }
    }
  },
  methods: {
  }
}
</script>
<style>
.ctk-q-title {
  text-align: left;
  font-size: 20px;
  font-weight: bold;
  line-height: 32px;
  color: #000000;
}
.ctk-q-pic-title {
  margin-top: 10px;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  color: #000000;
  -webkit-text-stroke: 1 rgba(0, 0, 0, 0);
}
.ctk-q-ab p {
  line-height: 32px;
  height: 32px;
  margin: 0 !important;
  font-size: 16px;
}
.ctk-q-xl p {
  line-height: 32px;
  font-size: 16px;
}
</style>
